import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';

const LogoutPage: React.FC = function () {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    async function logout() {
      await firebase.auth().signOut();
      navigate('/');
    }

    logout();
  }, []);

  return null;
};

export default LogoutPage;
